import React from "react";
import { HashRouter, Route, Routes, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import store from "./redux/store";
import {
  MuiPickersUtilsProvider,

} from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr";
import PickerDateFns from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import HashLoader from "react-spinners/HashLoader"
import ProtectedRoute from "./components/pages/protectedRoutes/protectedRoute";

import "./styles.css";





const loading = () => (
  <div className=" text-center d-flex flex-column justify-content-center align-items-center mx-auto" style={{ height: 400, marginTop: 20, marginBottom: 20 }}><HashLoader color="#067BE3" loading={true} size={50} /></div>

);


// Containers
const DefaultLayout = Loadable({
  loader: () => import("./components/pages/defaultLayout"),
  loading
});



// Pages
const Login = Loadable({
  loader: () => import("./components/pages/login"),
  loading
});
const Login_Admin = Loadable({
  loader: () => import("./components/pages/loginAdmin"),
  loading
});
const Signup = Loadable({
  loader: () => import("./components/pages/signup"),
  loading
});
const Page404 = Loadable({
  loader: () => import("./components/pages/404"),
  loading
});
const GetAccount = Loadable({
  loader: () => import("./components/pages/forgotPassword/getAccount"),
  loading
});

const GetSecurityCodeByEmail = Loadable({
  loader: () => import("./components/pages/forgotPassword/getCodeSent"),
  loading
});
const GetSecurityCodeBySMS = Loadable({
  loader: () => import("./components/pages/forgotPassword/getCodeSent"),
  loading
});

const ChangePassword = Loadable({
  loader: () => import("./components/pages/forgotPassword/changePassword"),
  loading
});

const ContactAdmin = Loadable({
  loader: () => import("./components/pages/forgotPassword/contactAdmin"),
  loading
});

const PublicPage = Loadable({
  loader: () => import("./components/pages/public"),
  loading
});
const ZoomPage = Loadable({
  loader: () => import("./components/main/pagesContainers/zoom/pageMeeting"),
  loading
});
const Page500 = Loadable({
  loader: () => import("./components/pages/500"),
  loading
});

class App extends React.Component {

  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <MuiPickersUtilsProvider utils={PickerDateFns} locale={frLocale}>
            <SnackbarProvider>

              <Routes>
                <Route

                  path="/500"
                  name="500 Page"
                  element={<Page500 />}
                />
               
                <Route

                  path="/getAccount"
                  name="getAccount Page"
                  element={<GetAccount />}
                />
                <Route

                  path="/getSecurityCodeByEmail"
                  name="GetSecurityCodeByEmail Page"
                  element={<GetSecurityCodeByEmail />}
                //render={<props => <GetSecurityCodeByEmail {...props/>} />/>}
                />
                <Route

                  path="/getSecurityCodeBySMS"
                  name="GetSecurityCodeBySMS Page"
                  element={<GetSecurityCodeBySMS />}
                //render={props => <GetSecurityCodeBySMS {...props/>} />/>}
                />
                <Route

                  path="/changePassword"
                  name="ChangePassword Page"
                  element={<ChangePassword />}
                />
                <Route

                  path="/contactAdmin"
                  name="GetSecurityCode Page"
                  element={<ContactAdmin />}
                />
                <Route path="/404" name="Page 404" element={<Page404 />} />
                <Route path="/login" name="Page 404" element={<Login />} />
                <Route path="/loginAdmin" name="Page 404" element={<Login_Admin />} />
                <Route path="/public" name="Public page" element={<PublicPage />} />
                
                <Route element={<ProtectedRoute />}>
                 <Route path="/*" name="Home student page" element={<DefaultLayout />} />
                </Route>

              </Routes>

            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </HashRouter>
      </Provider>


    );
  }
}


export default App;


